/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
// import "./layout.css"
import Footer from "./footer"

const Layout = ({ children, activateModal }) => {

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")("a[href*=\"#features\"]")
    require("smooth-scroll")("a[href*=\"#products\"]")
  }

  return (
    <>
      <Header activateModal={activateModal}/>
      <main>{children}</main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
