import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby'
import { Navbar, Nav, Container } from "react-bootstrap"
import logo from "../images/logo/white.svg"
import ig from "../images/icons/instagram-white.svg"
import { RiShoppingBasket2Line } from 'react-icons/ri';
import { FaLanguage } from 'react-icons/fa';
import { ImBlog } from 'react-icons/im';


const Header = ({ activateModal }) => (
  <header
    style={{
      background: `#000000ea`,
    }}
  >
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand href="/"><img src={logo} alt="Logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
        </Nav>
        <Nav>
          <Nav.Link href="https://vaire.eu">English<FaLanguage></FaLanguage></Nav.Link>
          <Nav.Link href="https://instagram.com/vaire_pl">Lookbook<img src={ig} alt="Vaire Instagram" /></Nav.Link>
          <Nav.Link href="https://cociekawe.pl/kategoria/maski-antysmogowe/">Sklep<RiShoppingBasket2Line></RiShoppingBasket2Line></Nav.Link>
          <Link to="/blog" className="styled-button">Blog<ImBlog></ImBlog></Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
