import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"

class Footer extends Component {
  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col>
              <p className="upper">
                © {this.currentYear()} Vaire | Wszystkie prawa zastrzeżone
              </p>
              <small>
                <a href="https://szymonbubala.pl/">Strona stworzona w Gatsby przez Szymon Bubała</a>
              </small>
            </Col>
            <Col md={6}>
              <small>
                E-mail: szymon@vaire.pl
              <br />
                Tel.: +48 574 605 627
              <br />
                Godziny pracy: 10:00 - 18:00 (pon - pt)
              </small>
            </Col>
          </Row>
        </Container>

      </footer>
    )
  }

  currentYear() {
    return new Date().getFullYear();
  }
}
export default Footer